<template>
  <div class="main_style">
    <div class="header">
      <div class="header-text">
        <p class="header-title">积分商城</p>
        <p class="header-desc">
          方案应用区块链技术，构建公开、透明的积分商城。商城可发行区块链积分，并支持 多商铺多积分模式，构建“电商+积分+区块链”生态系统。
        </p>
      </div>
    </div>
    <!-- 产品介绍 -->
    <div class="intro">
      <div class="commom-title">方案介绍</div>
      <div class="intro-content">
        <p>
          区块链积分商城方案是分析传统积分商城痛点并结合市场需求后设计的方案，可有效解决积分发行不透明、通兑过程繁琐、流通率低等问题。<br/><br/>应用了区块链技术的积分商城，在包含了传统电商平台功能以外，支持运营方和商户发行属于自己的区块链积分，并设计适合自身业务的积分发行、使用规则。运营方可设定联盟中的通用积分，商户间可自行规定积分通兑规则，实现积分在联盟内自由流转、通兑，提升消费者粘性，实现共享会员。记录在区块链上的积分规则，消费者可随时查看，不必再担心商户暗箱操作；消费者可在系统内按规则通兑积分，将积分用于所需处。<br/><br/>方案旨在利用区块链积分技术，优化传统积分商城方案，解决传统积分存在的问题，打造一个公开透明、灵活配置、有效提升各方效率、助力商户突破营销瓶颈的区块链积分商城。
        </p>
      </div>
    </div>
    <!-- 产品架构 -->
    <div class="framework">
      <div class="commom-title">方案架构</div>
      <div class="framework-content">
        <img src="https://image.peerfintech.cn/peerfintech_website_v2/solution/point/framework.png" />
      </div>
    </div>
    <!-- 产品优势 -->
    <div class="advantage">
      <div class="commom-title">受益角色</div>
      <div class="advantage-content">
        <el-row :gutter="24">
          <el-col :span="12"
            ><div class="grid-content">
              <div class="img">
                <img src="@/assets/img/solution/point/role-1.png" />
              </div>
              <div class="title">运营方</div>
              <div class="text">
                积分商城平台运营方可对各方信息进行整合，利用区块链技术的特点对联盟数据进行统一管理，优化运营结构、提升运营效率。
              </div>
            </div></el-col
          >
          <el-col :span="12"
            ><div class="grid-content">
              <div class="img">
                <img src="@/assets/img/solution/point/role-2.png" />
              </div>
              <div class="title">商家</div>
              <div class="text">
                区块链积分助力商家进行更加公开可信的营销活动，商家间亦可互相引流、共享会员，突破现有营销瓶颈。
              </div>
            </div></el-col
          >
        </el-row>
        <el-row :gutter="24">
          <el-col :span="12"
            ><div class="grid-content">
              <div class="img">
                <img src="@/assets/img/solution/point/role-3.png" />
              </div>
              <div class="title">消费者</div>
              <div class="text">
                公开透明的积分体系可保障消费者权益，并且，消费者可统一管理联盟发行的积分，并在联盟商家之间自由通兑积分，提升积分价值。
              </div>
            </div></el-col
          >
        </el-row>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  mounted() {
    // 页面回退顶部
    window.scrollTo(0, 0);
  },
};
</script>
<style lang="scss" scoped>
.main_style {
  background-color: #ffffff;
  .content-width {
    width: 1200px;
    margin: 0px auto;
  }
  .commom-title {
    padding: 70px 0px 50px;
    font-size: 32px;
    font-family: "PingFangSC-Medium";
    color: rgba(0, 0, 0, 0.85);
    line-height: 45px;
    text-align: center;
  }
  .header {
    width: 100%;
    height: 540px;
    background: url("https://image.peerfintech.cn/peerfintech_website_v2/solution/point/bg.png")
      center/cover no-repeat;
    .header-text {
      @extend .content-width;
      .header-title {
        font-size: 36px;
        font-family: "PingFangSC-Medium";
        color: #000000;
        line-height: 50px;
        padding-top: 160px;
      }
      .header-desc {
        width: 510px;
        color: rgba(0,0,0,0.75);
        font-size: 20px;
        line-height: 33px;
        margin-top: 36px;
      }
    }
  }
  .intro {
    .intro-content {
      @extend .content-width;
      font-size: 18px;
      color: rgba(0, 0, 0, 0.65);
      line-height: 25px;
    }
  }
 
  .framework {
    padding-bottom: 70px;
    .framework-content {
      @extend .content-width;
      img {
        width: 100%;
      }
    }
  }
  .advantage {
    background: #f4f7fc;
    .advantage-content {
      @extend .content-width;
      padding-bottom: 70px;
      .el-row {
        margin-bottom: 24px;
        &:last-child {
          margin-bottom: 0;
        }
      }
      .grid-content {
        height: 370px;
        background: #ffffff;
        box-shadow: 3px 3px 15px 0px rgba(0, 0, 0, 0.1);
        padding: 40px 48px 0px 40px;
        .img {
          width: 124px;
          height: 124px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .title {
          padding: 24px 0px;
          font-size: 22px;
          font-family: "PingFangSC-Medium";
          color: rgba(0, 0, 0, 0.85);
          line-height: 30px;
        }
        .text {
          font-size: 16px;
          color: rgba(0, 0, 0, 0.45);
          line-height: 22px;
        }
      }
    }
  }
}
</style>
